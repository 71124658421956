import JSEncrypt from 'jsencrypt'

/**
 * 将一维的扁平数组转换为多层级对象
 * @param {[type]} list 一维数组，数组中每一个元素需包含code和parentCode两个属性
 * @return {[type]} tree 多层级树状结构
 */
export function transformListToTree(list) {
    const temp = {}
    const tree = []
    for (const i in list) {
        temp[list[i].code] = list[i]
    }
    for (const i in temp) {
        temp[i].label = temp[i].name
        if (temp[i].parentCode) {
            if (!temp[temp[i].parentCode]) {
                console.log(temp[i].parentCode, temp[temp[i].parentCode])
            }
            if (!temp[temp[i].parentCode]) { temp[temp[i].parentCode] = {} }
            if (!temp[temp[i].parentCode].children) {
                temp[temp[i].parentCode].children = []
                // temp[temp[i].parentCode].hasChildren = true
            }
            temp[temp[i].parentCode].children.push(temp[i])
        } else {
            tree.push(temp[i])
        }
    }
    return tree
}

/**
 * 过滤list中所有元素的id信息
 * @param {[type]} list 一维数组，数组中每一个元素需包含id属性
 * @param {[type]} type 返回值类型：str 输出id字符串，以逗号为分隔符；默认输出id数组
 * @return {[type]} ids 所有元素的id列表
 */
export function getIdList(list, resultType) {
    const ids = []
    for (const i in list) {
        ids.push(list[i].id)
    }
    if (resultType === 'str') {
        ids.join(',')
    }
    return ids
}

/**
 * 过滤list中所有元素的id信息
 * @param {[type]} list 一维数组，数组中每一个元素需包含id属性
 * @param {[type]} type 返回值类型：str 输出id字符串，以逗号为分隔符；默认输出id数组
 * @return {[type]} ids 所有元素的id列表
 */
export function getRSApassword(publicKey, pwd) {
    const encryptStr = new JSEncrypt()
    encryptStr.setPublicKey(publicKey)
    const encrypted = encryptStr.encrypt(pwd)
    return encrypted
}
